// extracted by mini-css-extract-plugin
export var active = "Sidebar-module--active--JnnpQ";
export var headerScrolled = "Sidebar-module--headerScrolled--ZUBlx";
export var listWrapper = "Sidebar-module--listWrapper--Cke1W";
export var root = "Sidebar-module--root---TDhz";
export var show = "Sidebar-module--show--xCDAN";
export var sidebarWrapper = "Sidebar-module--sidebarWrapper--Me9sm";
export var title = "Sidebar-module--title--UlGPD";
export var tocItem = "Sidebar-module--tocItem--Z-RPJ";
export var toggleButton = "Sidebar-module--toggleButton--qqnwB";
export var toggleLabel = "Sidebar-module--toggleLabel--73jSi";